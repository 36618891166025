@font-face {
  font-family: 'Exo2';
  src: url('../../assets/fonts/Exo2-Thin.woff2') format('woff2'),
    url('../../assets/fonts/Exo2-Thin.woff') format('woff'),
    url('../../assets/fonts/Exo2-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'Exo2';
  src: url('../../assets/fonts/Exo2-ThinItalic.woff2') format('woff2'),
    url('../../assets/fonts/Exo2-ThinItalic.woff') format('woff'),
    url('../../assets/fonts/Exo2-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}
/*@font-face {*/
/*	font-family: 'Exo2';*/
/*	src: url('../../assets/fonts/Exo2-Italic.woff2') format('woff2'),*/
/*	url('../../assets/fonts/Exo2-Italic.woff') format('woff'),*/
/*	url('../../assets/fonts/Exo2-Italic.ttf') format('truetype');*/
/*	font-weight: 1;*/
/*	font-style: italic;*/
/*}*/
@font-face {
  font-family: 'Exo2';
  src: url('../../assets/fonts/Exo2-ExtraLight.woff2') format('woff2'),
    url('../../assets/fonts/Exo2-ExtraLight.woff') format('woff'),
    url('../../assets/fonts/Exo2-ExtraLight.ttf') format('truetype');
  /*src: url('Exo2-ExtraLight.woff2') format('woff2') url('Exo2-ExtraLight.woff') format('woff');*/
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: 'Exo2';
  src: url('../../assets/fonts/Exo2-ExtraLightItalic.woff2') format('woff2'),
    url('../../assets/fonts/Exo2-ExtraLightItalic.woff') format('woff'),
    url('../../assets/fonts/Exo2-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: 'Exo2';
  src: url('../../assets/fonts/Exo2-Light.woff2') format('woff2'),
    url('../../assets/fonts/Exo2-Light.woff') format('woff'),
    url('../../assets/fonts/Exo2-Light.ttf') format('truetype');
  /*src: url('Exo2-Light.woff2') format('woff2') url('Exo2-Light.woff') format('woff');*/
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Exo2';
  src: url('../../assets/fonts/Exo2-LightItalic.woff2') format('woff2'),
    url('../../assets/fonts/Exo2-LightItalic.woff') format('woff'),
    url('../../assets/fonts/Exo2-LightItalic.ttf') format('truetype');
  /*src: url('Exo2-LightItalic.woff2') format('woff2') url('Exo2-LightItalic.woff') format('woff');*/
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'Exo2';
  src: url('../../assets/fonts/Exo2-Regular.woff2') format('woff2'),
    url('../../assets/fonts/Exo2-Regular.woff') format('woff'),
    url('../../assets/fonts/Exo2-Regular.ttf') format('truetype');
  /*src: url('Exo2-Regular.woff2') format('woff2') url('Exo2-Regular.woff') format('woff');*/
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Exo2';
  src: url('../../assets/fonts/Exo2-Italic.woff2') format('woff2'),
    url('../../assets/fonts/Exo2-Italic.woff') format('woff'),
    url('../../assets/fonts/Exo2-Italic.ttf') format('truetype');
  /*src: url('Exo2-Italic.woff2') format('woff2') url('Exo2-Italic.woff') format('woff');*/
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'Exo2';
  src: url('../../assets/fonts/Exo2-Medium.woff2') format('woff2'),
    url('../../assets/fonts/Exo2-Medium.woff') format('woff'),
    url('../../assets/fonts/Exo2-Medium.ttf') format('truetype');
  /*src: url('Exo2-Medium.woff2') format('woff2') url('Exo2-Medium.woff') format('woff');*/
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Exo2';
  src: url('../../assets/fonts/Exo2-MediumItalic.woff2') format('woff2'),
    url('../../assets/fonts/Exo2-MediumItalic.woff') format('woff'),
    url('../../assets/fonts/Exo2-MediumItalic.ttf') format('truetype');
  /*src: url('Exo2-MediumItalic.woff2') format('woff2') url('Exo2-MediumItalic.woff') format('woff');*/
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'Exo2';
  src: url('../../assets/fonts/Exo2-SemiBold.woff2') format('woff2'),
    url('../../assets/fonts/Exo2-SemiBold.woff') format('woff'),
    url('../../assets/fonts/Exo2-SemiBold.ttf') format('truetype');
  /*src: url('Exo2-SemiBold.woff2') format('woff2') url('Exo2-SemiBold.woff') format('woff');*/
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Exo2';
  src: url('../../assets/fonts/Exo2-SemiBoldItalic.woff2') format('woff2'),
    url('../../assets/fonts/Exo2-SemiBoldItalic.woff') format('woff'),
    url('../../assets/fonts/Exo2-SemiBoldItalic.ttf') format('truetype');
  /*src: url('Exo2-SemiBoldItalic.woff2') format('woff2') url('Exo2-SemiBoldItalic.woff') format('woff');*/
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: 'Exo2';
  src: url('../../assets/fonts/Exo2-Bold.woff2') format('woff2'),
    url('../../assets/fonts/Exo2-Bold.woff') format('woff'),
    url('../../assets/fonts/Exo2-Bold.ttf') format('truetype');
  /*src: url('Exo2-Bold.woff2') format('woff2') url('Exo2-Bold.woff') format('woff');*/
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Exo2';
  src: url('../../assets/fonts/Exo2-BoldItalic.woff2') format('woff2'),
    url('../../assets/fonts/Exo2-BoldItalic.woff') format('woff'),
    url('../../assets/fonts/Exo2-BoldItalic.ttf') format('truetype');
  /*src: url('Exo2-BoldItalic.woff2') format('woff2') url('Exo2-BoldItalic.woff') format('woff');*/
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: 'Exo2';
  src: url('../../assets/fonts/Exo2-ExtraBold.woff2') format('woff2'),
    url('../../assets/fonts/Exo2-ExtraBold.woff') format('woff'),
    url('../../assets/fonts/Exo2-ExtraBold.ttf') format('truetype');
  /*src: url('Exo2-ExtraBold.woff2') format('woff2') url('Exo2-ExtraBold.woff') format('woff');*/
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'Exo2';
  src: url('../../assets/fonts/Exo2-ExtraBoldItalic.woff2') format('woff2'),
    url('../../assets/fonts/Exo2-ExtraBoldItalic.woff') format('woff'),
    url('../../assets/fonts/Exo2-ExtraBoldItalic.ttf') format('truetype');
  /*src: url('Exo2-ExtraBoldItalic.woff2') format('woff2') url('Exo2-ExtraBoldItalic.woff') format('woff');*/
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: 'Exo2';
  src: url('../../assets/fonts/Exo2-Black.woff2') format('woff2'),
    url('../../assets/fonts/Exo2-Black.woff') format('woff'),
    url('../../assets/fonts/Exo2-Black.ttf') format('truetype');
  /*src: url('Exo2-Black.woff2') format('woff2') url('Exo2-Black.woff') format('woff');*/
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Exo2';
  src: url('../../assets/fonts/Exo2-BlackItalic.woff2') format('woff2'),
    url('../../assets/fonts/Exo2-BlackItalic.woff') format('woff'),
    url('../../assets/fonts/Exo2-BlackItalic.ttf') format('truetype');
  /*src: url('Exo2-BlackItalic.woff2') format('woff2') url('Exo2-BlackItalic.woff') format('woff');*/
  font-weight: 900;
  font-style: italic;
}
