@import './fonts.css';

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ul,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
  touch-action: pan-x pan-y;
  /* min-height: 100%; */
  font-family: 'Exo2', sans-serif;
  background: #11011e;
}

html {
  /* min-height: 100vh; */
}

/* @supports (min-height: 100dvh) {
  html {
    min-height: 100dvh;
  }
} */

#root {
  /* height: 100%; */
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

button {
  outline: none;
  border: 0;
}

* {
  box-sizing: border-box;
}

ul,
li {
  /*padding: 0;*/
  /*margin: 0;*/
  /*list-style-type: none;*/
}

a {
  text-decoration: none;
  color: #000;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  border-radius: 4px;
  background: rgba(9, 13, 41, 0.2);
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background: #8d87c6;
}

::-webkit-scrollbar-corner {
  background: rgba(9, 13, 41, 0.2);
}
